.Folow {
    position: relative;
}

.Follow i {
    position: absolute;
    top: 1.9rem;
    right: 1.2rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.Available {
    position: absolute;
    background-color: hotpink;
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
    border-radius: 5px;
    top: 3rem;
    right: 1rem;
    display: none;
}

.Follow:hover p{
    display: block;
}
