.Container {
    background-color: white;
    font-size: 1rem;
    font-family: 'Montserrat';
    margin: 0;
}

.PageTitle {
    padding: 2.5rem 1rem;
    margin: 0;
    text-align: center;
    color: #424242;
    font-weight: 500;
    letter-spacing: 0.15rem;
    font-size: 1.4rem;
    background-color: aliceblue;
}

.Title {
    padding: 1rem;
    margin: 0;
    text-align: center;
    color: #F76B8A;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: aliceblue;
}

.Text {
    text-align: center;
    padding: 1rem;
    padding-bottom: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    background-color: aliceblue;
}

.Examples {
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 0.5rem;
}

.Examples i {
    color: #424242;
    font-size: 0.9rem;
    padding-right: 0.2rem;
    padding-left: 1rem;
}


.Images {
   width: 100%;
   margin: auto;
   text-align: center;
   padding: 0;
   margin-top: 2rem;
   margin-bottom: 2rem;
}

.Images img {
    width: 60%;
    height: 60%;
}

.More {
    color: rgb(110, 107, 107);
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.EmailContainer {
    text-align: center;
    width: 100%;
    margin-top: 3rem;
    padding-bottom: 7rem;
}

.EmailButton {
    font-family: 'Montserrat';
    margin: auto;
    border-radius: 10px;
    max-width: 80%;
    background-color: #F76B8A;
    box-shadow: 1px 2px 2px #ddd;
    color: white;
}

.EmailButton a {
    font-size: 1rem;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.8rem 1rem;
    font-weight: 600;
}

.EmailButton i {
    font-weight: 600;
}