.Category {
   font-size: 0.7rem;
   font-weight: 400;
   color: #2B313E;
   color: gray;
}

.Category i {
   padding-right: 0.15rem;
   font-size: 0.7rem;
}

.DefaultCategory {
   display: none;
}

.Category:nth-child(2) {
   padding-left: 1rem;
}