
.Container {
   width: 100%;
   margin: 0;
   padding: 0;
   overflow: hidden;
}

.HeaderContainer {
   text-align: center;
   background-color: aliceblue;
   padding-top: 2.5rem;
   padding-bottom: 1.5rem;
}

.Head {
   padding: 0;
   font-size: 1.5rem;
   font-weight: 600;
   letter-spacing: 0.3px;
}

.SubHeader {
   padding: 1rem;
   padding-bottom: 0.5rem;
   color: grey;
   font-weight: 500;
}

.Highlight {
   color: #42CDAD;
}

.CheckContainer {
   margin: auto;
   width: 100%;
   color: #ccc;
   list-style: none;
   display: inline-flex;
   justify-content: space-between;
   padding-top: 2rem;
}

.CheckContainer li {
   width: 32%;
   font-size: 0.85rem;
}

.CheckContainer i {
   color: #42CDAD;
}

.Bold {
   font-weight: 600;
   color: #ccc;
}

.PackageInfo {
   text-align: center;
   padding: 1.5rem 1rem;
   padding-top: 0;
   font-size: 1.1rem;
}

.PackageInfo p {
   font-size: 0.9rem;
}

.AccountContainer {
   padding: 1rem 0;
   text-align: center;
   font-size: 0.9rem;
}

.TrialImage {
   width: 100%;
   text-align: center;
   padding: 1rem;
}

.TrialImage img {
   width: 60%;
}

.MainTitle {
   color: #f06595;
   text-align: center;
   letter-spacing: 0.1px;
   font-weight: 600;
   font-size: 1.1rem;
   padding: 1rem;
   padding-top: 2rem;
}

.Credit {
   padding: 1rem;
   padding-bottom: 4.5rem;
   text-align: center;
   color: #ccc;
   font-size: 0.9rem;
}

