.TimerContainer {
   width: 85%;
   min-height: 700px;
   margin: auto;
   padding-top: 1rem;
   padding-bottom: 5rem;
   /* display: flex; */
   display: grid;
   grid-template-columns: 100%;
   grid-auto-rows: minmax(100px, auto);
   grid-gap: 1.5rem;
   /* margin-left: -8px;
   min-height: 70.5vh;
   margin: auto;
   text-align: center;
   position: relative;
   top: 1.5rem;
   display: table; */
   overflow: hidden;
}


@media (min-width: 700px) {
   .TimerContainer {
        grid-template-columns: repeat(2, 47%);
        /* min-height: 1000px; */
        width: 90%;
        grid-gap: 2.5rem;
   }
}

@media (min-width: 1000px) {
   .TimerContainer {
       margin: auto;
       width: 90%;
       grid-gap: 2rem;
       grid-template-columns: repeat(3, 31%);
       margin-bottom: 2rem;
       /* min-height: 700px; */
   }
}

@media (min-width: 1200px) {
   .TimerContainer {
       margin: auto;
       width: 90%;
       grid-gap: 1.5rem;
       grid-template-columns: repeat(4, 23.5%);
       margin-bottom: 2rem;
       /* min-height: 700px; */
   }
}

@media (min-width: 1500px) {
   .TimerContainer {
       grid-template-columns: repeat(5, 18.5%);
       margin-bottom: 3rem;
   }
}