.TimerImage {
   margin: 0;
   padding: 0;
}

.TimerImage img {
   margin: 0;
   padding: 0;
   margin-top: 0.5rem;
   width: 11rem;
   height: 11rem;
   border-radius: 50%;
   cursor: pointer;
}
