.Container {
    width: 100%;
    margin: auto;
    padding: 0;
    overflow: hidden;
    font-family: 'Montserrat';
}

.PontimerContainer {
   text-align: center;
   padding: 1rem;
   font-size: 1rem;
   font-family: 'Montserrat';
   /* padding: 2rem 0; */
   /* border-bottom: 0.25px solid #eee; */
}

.PontimerTitle {
   font-size: 1.3rem;
   font-weight: 600;
   color: #F76B8A;
   margin-bottom: 1.5rem;
}

.Types {
   display: inline-flex;
   min-height: 200px;
   margin-bottom: 1rem;
}

.Types i{
   font-size: 3rem;
   margin: 1rem;
}

.Type1 {
   color: #424242;
   padding: 1rem;
   font-size: 1rem;
   width: 44%;
   margin: 0.6rem;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
   font-size: 0.9rem;
}

.Type2 {
   color: #424242;
   padding: 1rem;
   margin: 0.6rem;
   font-size: 1rem;
   width: 44%;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
   font-size: 0.9rem;
}


.ExampleContainer {
   font-size: 0.8rem;
   padding: 1rem;
   border-radius: 5px;
   background-color: #2B313E;
   color: white;
   padding-bottom: 1rem;
}

.ExampleTitle {
   padding: 1rem;
   color: white;
   font-size: 1.1rem;
   letter-spacing: 0.3px;
   font-weight: 500;
}

.SubTitle {
   color: white;
   font-weight: 500;
   font-style: normal;
   font-size: 0.9rem;
}

.SubTitle i {
   color: #42CDAD;
   padding-top: 1rem;
   font-size: 0.8rem;
}

.Example1, .Example2 {
   list-style: none;
   color: white;
   display: inline-flex;
   justify-content: space-between;
   padding: 0.5rem;
   margin: 0;
}

.Example1 span {
   color: #F76B8A;
   font-weight: 600;
}

.Example2 span {
   color: #F76B8A;
   font-weight: 600;
}

.Example1 li {
   font-family: 'Montserrat';
   font-weight: 500;
   background-color: white;
   color: #424242;
   width: 29%;
   height: 170px;
   border-radius: 5px;
    padding: 0.2rem;
}

.Example2 li {
   font-family: 'Montserrat';
   font-weight: 500;
   background-color: white;
   color: #424242;
   width: 29%;
   height: 170px;
   border-radius: 5px;
   padding: 0.2rem;
}

.ExampleImage {
   width: 100%;
   height: 55%;
   border-radius: 5px 5px 0 0;
   padding: 0;
   margin: 0;
}

.ExampleClosure {
   padding: 1rem;
}

.Highlight {
   color: #42CDAD;
   font-weight: 600;
}