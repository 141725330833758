
.Future {
   padding: 2rem 1rem;
   padding-bottom: 1rem;
}

.Title {
   color: #F76B8A;
   text-align: center;
   letter-spacing: 0.1px;
   font-weight: 600;
   font-size: 1.1rem;
}

.Card {
   border-radius: 5px;
   padding: 1rem;
   margin: 2rem 0;
   position: relative;
   box-shadow: 1px 2px 10px #C4C4C4;
   line-height: 1.4rem;
   letter-spacing: 0.5px;
   text-align: center;
}

.Name {
   font-size: 1.2rem;
   font-weight: 500;
   font-family: 'Roboto';
   letter-spacing: 2px;
}

.Icon {
   position: absolute;
   right: 1.5rem;
   top: 1.8rem;
   font-size: 1.3rem;
}

.Button {   
   text-align: right;
   color: white;
   background-color: #58ACBB;
   background-color: #F76B8A;
   border: none;
   border-radius: 5px;
   font-weight: 500;
   padding: 0.5rem 1rem;
   font-size: 0.9rem;
}

.Italic {
   font-style: italic;
   font-size: 0.9rem;
}

.Email{
   padding: 1rem;
   text-align: center;
   width: 100%;
   font-size: 1rem;
   padding-bottom: 0;
}

.Email a {
   text-decoration: none;
   color: #484646;
   font-weight: 600;
   cursor: pointer;
}


@media(min-width: 700px){
   .Button{
       left: 28rem;
       font-size: 1.1rem;
   }
   .Card {
       font-size: 1.1rem;
   }

   .Future {
       padding: 0 3rem;
   }
}

@media(min-width: 1000px){
   .Title {
       padding: 0 2rem;
   }

   .Future {
       padding: 1.5rem;
   }

   .Packages {
       display: inline-flex;
       width: 100%;
       text-align: center;
       margin: auto;
   }

   .Card {
       margin: 2rem;
       text-align: left;
   }
  
   .Button {
       font-size: 1.2rem;
       position: static;
       float: right;
   }

   .Email {
       text-align: right;
       padding: 0 2rem;
   }
}
