
.Table {
   color: white;
   font-weight: 400;
   font-size: 0.9rem;
   position: relative;
   text-align: center;
   font-family: 'Montserrat';
   line-height: 2rem;
   border-collapse: collapse;
   border: none;
   margin-left: 0.5rem;
   background-color: #6A6A6A;
   margin-bottom: 3rem;
}

.Table tr:nth-child(odd) {
   background-color: #424242;
}


.FreeWidget {
   background-color: #E1DFDF;
   color: black;
   text-transform: uppercase;
   padding: 0.3rem;
   width: 80px;
}

.BasicWidget {
   background-color: #8BAFB3;
   color: black;
   text-transform: uppercase;
   padding: 0.3rem;
   width: 80px;
}

.PremiumWidget {
   background-color: #42CDAD;
   color: black;
   text-transform: uppercase;
   padding: 0.3rem;
   width: 80px;
}

.Features {
   text-align: left;
   padding-left: 0.2rem;
   font-size: 0.7rem;
   border: none;
}

.Show {
   display: none;
}

.PriceF {
   font-size: 0.9rem;
   border: none;
   color: white;
}

.PriceB {
   font-size: 0.9rem;
   border: none;
   color: white;
}

.PriceP {
   font-size: 0.9rem;
   border: none;
   color: white;
}

.Icon {
   font-size: 0.7rem;
}

.Empty {
   /* background-color: #303030; */
   border: none;
   color: #C4C4C4;
   margin: 1rem;
   font-size: 0.5rem;
   text-transform: uppercase;
   text-align: center;
}

/* .Hide {
   display: none;
} */

@media(min-width: 700px){
   .Table {
       width: 90%;
       margin: auto;
       text-align: center;
       font-size: 1rem;
       margin-top: 3rem;
   }

   .Show {
       display: inline-block;
   }

   /* .Hide {
       display: none;
   } */

   .Widgets {
       width: 100%;
   }

   .Features {
       font-size: 1.2rem;
       height: 3rem;
   }

   .FreeWidget {
       padding: 0.3rem;
       line-height: 10rem;
   }

   .BasicWidget {
       padding: 0.3rem;
   }

   .PremiumWidget {
       padding: 0.3rem;
   }

   .PriceF {
       font-size: 1.1rem;
       width: 25%;
       background-color: #E1DFDF;
       color: black;
   }

   .PriceB {
       font-size: 1.1rem;
       width: 25%;
       background-color: #8BAFB3;
       color: black;
   }

   .PriceP {
       font-size: 1.1rem;
       width: 25%;
       background-color: #42CDAD;
       color: black;
   }

   .Empty {
       font-size: 1.5rem;
       text-align: center;
   }
}



@media(min-width: 1000px){
   .Table {
       width: 85%;
       margin: auto;
       text-align: center;
       margin-bottom: 1rem;
       margin-top: 3rem;
   }

   .Show {
       display: inline-block;
   }

   /* .Hide {
       display: none;
   } */

   .Features {
       font-size: 1rem;
   }
  
   .FreeWidget {
       padding: 0.3rem;
       width: 100px;
       line-height: 4rem;
   }

   .BasicWidget {
       padding: 0.3rem;
       width: 100px;
   }

   .PremiumWidget {
       padding: 0.3rem;
       width: 100px;
   }

   .PriceF {
       font-size: 1.1rem;
       width: 25%;
       background-color: #E1DFDF;
       color: black;
   }

   .PriceB {
       font-size: 1.1rem;
       width: 25%;
       background-color: #8BAFB3;
       color: black;
   }

   .PriceP {
       font-size: 1.1rem;
       width: 25%;
       background-color: #42CDAD;
       color: black;
   }

   .Empty {
       font-size: 1.5rem;
       text-align: left;
   }
}


