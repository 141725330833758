
.Container {
   width: 90%;
   margin: auto;
   font-family: 'Montserrat';
   padding-top: 1rem;
}

.ListContainer {
   width: 100%;
   list-style: none;
   padding: 1rem;
   margin: auto;
   text-align: center;
   font-size: 1rem;
   border-radius: 5px;
   background-color: #2B313E;
   color: white;
}

.ListContainer li {
   font-family: 'Montserrat';
   font-size: 1rem;
   font-weight: 400;
   padding: 0.5rem 0;
}

.Title {
   text-align: center;
   padding: 0.5rem 1rem;
   font-size: 1.4rem;
   font-weight: 700;
   color: #F76B8A;
   letter-spacing: 0.1rem;
   font-family: 'Montserrat';
   padding-top: 2rem;
}

.Italic {
   font-style: italic;
   font-size: 0.9rem;
}

@media(min-width: 700px){
   .Text {
       line-height: 1.2rem;
       font-size: 1rem;
       width: 75%;
       display: inline-block;
   }
   .ImageContainer {
       width: 20%;
       padding: 1rem;
       display: inline-block;
   }
}

@media(min-width: 1000px){
       .Text {
       line-height: 1.2rem;
       font-size: 1rem;
       width: 55%;
       display: inline-block;
   }
   .ImageContainer {
       width: 20%;
       padding: 1rem;
       display: inline-block;
   }
}
