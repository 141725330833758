.Container {
   font-size: 0.9rem;
   margin: auto;
   margin-top: 5rem;
   padding: 0 2rem;
   width: 100%;
   text-align: center;
   margin-bottom: 5rem;
   background-color: #eee;
}

.Container div {
   background-color: #A54064;
   color: white;
   font-weight: 500;
   border-radius: 10px;
   margin: 0.4rem;
   padding: 0.5rem 0.7rem;
   font-size: 0.8rem;
   float: left;
   text-align: center;
   cursor: pointer;
   /* box-shadow: 1px 1px 2px #151414; */
   text-transform: capitalize;
   overflow: hidden;
}


.fas {
   font-size: 0.8rem;
}

.Container div:hover {
   background-color: #F76B8A;
   color: white;
   cursor: pointer;
}

.Container div.active {
   background-color: #F76B8A;
   color: white; 
}

@media (min-width: 700px) {
   .Container {
       font-size: 1rem;
       margin: 1rem;
       margin-top: 5rem;
   }
  
   .Container div {
       font-weight: 600;
       border-radius: 5px;
   }
}

@media (min-width: 1000px) {
   .Container {
      margin: 9rem 2rem;
      margin-top: 5rem;
   }

   .Container div {
       font-weight: 600;
       border-radius: 5px;
       margin: 0.7rem;
       padding: 0.6rem 0.7rem;
       font-size: 1.1rem;
   }
}