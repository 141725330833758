.BottomNavigationMobile {
   width: 100%;
   text-align: center;
   background-color: #33B3C3;
   color: white;
   position: fixed;
   bottom: 0;
   left: 0;
   z-index: 101;
   height: 3.6rem; 
   /* background-color: #A54064;
   background-color: #20c997;
   background-color: #4dabf7;
   background-color: #03060A;
   background-color: #237A71;
   background-color: #33B3C3;
   background-color: #48515E; */
}

.Container {
   width: 60%;
   height: 100%;
   align-items: center;
   display: inline-flex;
   justify-content: space-between;
}

.Container span {
   font-size: 1.2rem;
}


