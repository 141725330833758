.LoadMoreButton {
    width: 90%;
    border-radius: 6px;
    margin: auto;
    text-align: center;
    background-color: #52616B;
    color: white;
    margin-bottom: 6rem;
    margin-top: 0;
    font-size: 1.5rem;
    padding: 0.6rem;
    box-shadow: 1px 1px 6px grey;
    cursor: pointer;
}


.LoadMoreButton:hover {
    background-color: #F76B8A;
}

@media (min-width: 700px) {
    .LoadMoreButton {
        margin-bottom: 10rem;
        margin-top: 2rem;
    }
}

@media (min-width: 1000px) {
    .LoadMoreButton {
        margin-bottom: 5rem;
    }
}