.Container {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: white;
}

.Header {
    width: 100%;
    margin: 0;
    padding: 0 4rem;
    color: grey;
    font-weight: 500;
    font-size: 0.9rem;
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
    font-family: 'Montserrat';
    background-color: white;
    list-style: none;
    align-items: center;
    line-height: 3rem;
    border-bottom: 0.25px solid #ddd;
    /* background-color: #f06595; */
    /* background-color: #F76B8A; */
    /* background-color: #20c997; */
}

.Header li {
    width: 25%;
    text-align: center;
}

.Header li:hover{
    background-color: white;
    color: #f06595;
    width: 25%;
    text-align: center;
}
