
.Container {
   color: #424242;
   width: 90%;
   margin: auto;
   text-align: center;
   overflow: hidden;
   font-family: 'Montserrat';
}

.MainContainer {
   margin: auto;
   width: 100%;
}

.ChooseB {
   background-color: white;
   border-radius: 7px;
   padding: 2rem 1rem;
   font-weight: 500;
   box-shadow: 1px 3px 8px #ccc;
   margin: 1rem;
   margin-top: 1rem;
}

.Title1 {
   font-size: 1.4rem;
   font-weight: 600;
   letter-spacing: 0.5px;
   color: #58ACBB;
}

.Title2 {
   font-size: 1.4rem;
   font-weight: 600;
   letter-spacing: 0.5px;
   color: #42CDAD;
}

.List {
   list-style: none;
   padding: 0;
   padding-bottom: 1.5rem;
}

.List li {
   font-family: 'Montserrat';
   font-weight: 500;
   padding: 0.3rem 0;
}

.List i {
   padding-right: 0.5rem;
   font-size: 0.9rem;
   color: #42CDAD;
}

.ListTimer {
   color: grey;
   font-weight: 500;
}

.ChooseP {
   background-color: white;
   border-radius: 7px;
   padding: 1rem;
   font-weight: 500;
   box-shadow: 1px 3px 8px #ccc;
   margin: 1rem;
   margin-top: 3rem;
   padding-bottom: 2rem;
}

.PackageImage {
   width: 80%;
}

.Price {
   font-size: 1.1rem;
   font-weight: 600;
   margin: 0;
}

.ListTitle {
   font-weight: 600;
   letter-spacing: 0.1px;
   font-size: 1rem;
   padding-top: 1rem;
}

.TrialButton1 {
   font-weight: 600;
   font-size: 1rem;
   background-color: #58ACBB;
   color: white;
   letter-spacing: 0.5px;
   border-radius: 5px;
   padding: 0.8rem;
   margin: 0;
}

.TrialButton2 {
   font-weight: 600;
   font-size: 1rem;
   background-color: #42CDAD;
   color: white;
   letter-spacing: 0.5px;
   border-radius: 5px;
   padding: 0.8rem;
   margin: 0;
}

.FreeTrial {
   font-size: 1rem;
   padding: 1rem;
   padding-bottom: 0;
}

.SpecialOffer {
   font-size: 1rem;
   padding: 1rem;
   padding-bottom: 0;
}

.Highlight {
   color: #42CDAD;
   font-weight: 600;
}

.List {
   font-size: 0.9rem;
   color: grey;
}


