.Title {
   display: inline-block;
   font-size: 1.2rem;
   font-weight: 500;
   letter-spacing: 0.1rem;
   padding: 0;
   height: 48px;
   width: 90%;
   overflow: hidden;
}
