.Container {
    background-color: white;
    /* box-shadow: 1px 2px 2px #ccc; */
    width: 3rem;
    height: 3rem;
    position: fixed;
    bottom: 5.5rem;
    right: 0.6rem;
    z-index: 99;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    border: none;
    outline: none;
    cursor: pointer;
}

.Container:hover {
    background-color: #F76B8A;
}


.Container i {
    vertical-align: middle;
    color: #ccc;
    color: #F76B8A;
    font-size: 1.2rem;
    padding: 0.9rem;
}

.Container i:hover {
    color: white;
}

@media (min-width: 1000px) {
    .Container {
        right: 1.5rem;
        bottom: 6rem;
    }
}