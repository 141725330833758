.Conatiner {
   width: 100%;
   margin: 0;
   padding: 0;
   font-family: 'Montserrat';
}

.TitleContainer {
   font-size: 1.8rem;
   padding: 1rem;
   margin: 0;
   padding-top: 1.5rem;
   background-color: aliceblue;
   color: #424242;
   text-align: center;
}

.Main {
   font-weight: 600;
   margin: 0;
   padding: 0;
   color: #424242;
   font-family: 'Montserrat';
}

.MainSubTitle {
   width: 100%;
   margin: 0;
   padding: 1rem;
   padding-top: 1rem;
   background-color: aliceblue;
   list-style: none;
   font-weight: 500;
   font-style: italic;
   font-size: 0.9em;
   color: #424242;
   display: inline-flex;
   justify-content: space-between;
   margin-bottom: 2rem;
}

.MainSubTitle li {
   padding: 0;
   margin: 0;
   color: #424242;
   padding: 1rem 0.5rem;
   font-family: 'Montserrat';
   font-weight: 400;
   letter-spacing: 0.2px;
}

.MainSubTitle i {
   color: #42CDAD;
   margin-right: 0.3rem;
}

.SampleTimers {
   width: 100%;
}

.ValueContainer {
   /* background-color: red; */
   width: 100%;
   /* display: inline-block; */
   margin: auto;
   padding: 1rem;
   text-align: center;
   font-size: 1rem;
   font-weight: 400;
   font-family: 'Montserrat';
   /* border-bottom: 0.25px solid #eee; */
   padding-bottom: 2rem;
}

.ValueTitle {
   padding-top: 2rem;
   font-size: 1.35rem;
   font-weight: 600;
   color: #F76B8A;
   /* letter-spacing: 1px; */
}

.Value{
   padding: 0 1rem;
   display: inline-flex;
   vertical-align: middle;
   min-height: 200px;
}

.DontMissIcon {
   font-size: 3rem;
   color: #4dabf7;
}

.HappyIcon {
   font-size: 3rem;
   color: #42CDAD;
}

.Value1 {
   color: #424242;
   padding: 1rem;
   font-size: 1rem;
   width: 43%;
   margin: 1rem;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
}

.Value2 {
   color: #424242;
   padding: 1rem;
   margin: 1rem;
   font-size: 1rem;
   width: 43%;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
}


.Method {
   font-size: 0.93rem;
   font-weight: 500;
   margin: 0;
   padding: 0;
   background-color: #F76B8A;
   color: white;
   text-align: center;
   padding: 1rem;
}

.WhatContainer {
   padding: 1rem;
   text-align: center;
   font-size: 1rem;
   font-family: 'Montserrat';
   width: 100%;
   margin: auto;
   margin-top: 1rem;
   border-radius: 5px;
   /* border-bottom: 0.25px solid #eee; */
   padding-bottom: 1rem;
}

.WhatTitle {
   font-weight: 600;
   color: #F76B8A;
   /* padding-top: 1rem; */
   font-size: 1.25rem;
}

.WhatContainer span {
   color: #42CDAD;
   margin-right: 0.5rem;
}

.WhatContainer img {
   width: 50%;
   height: 50%;
}

.ImageTimer {
   width: 60%;
   margin: auto;
   padding: 0;
}

.ImageTimer img {
   margin: auto;
   align-items: center;
}

.TimerTitle {
   font-weight: 600;
   color: #F76B8A;
   font-size: 1rem;
}

.PuffinContainer {
   text-align: center;
   font-size: 1rem;
   font-family: 'Montserrat';
   width: 90%;
   position: relative;
   margin: auto;
   padding: 1rem;
   padding-bottom: 2rem;
   box-shadow: 1px 2px 5px #ccc;
}

.Puffin {
   width: 10rem;
   height: 10rem;
   border-radius: 50%;
}

.PuffinTitle {
   font-size: 1.3rem;
   font-weight: 700;
   color: #F76B8A;
   letter-spacing: 0.1rem;
}

.PuffinText {
   padding: 0.5rem;
   list-style: none;
}

.PuffinText li {
   font-family: 'Montserrat';
   font-size: 1rem;
   font-weight: 400;
   padding: 0.5rem 0;
   letter-spacing: 0.3px;
}

.TextHighlight {
   font-weight: 500;
}

.Credit {
   font-size: 0.8rem;
   color: #ccc;
}

.Bold {
   font-weight: 700;
}

.ContactContainer {
   padding: 1rem;
   text-align: center;
   font-size: 0.9rem;
   font-family: 'Montserrat';
   padding-bottom: 6rem;
   font-weight: 500;
   padding-top: 2rem;
}

.Images {
   width: 100%;
   padding-top: 1rem;
}

.ContactTitle {
   letter-spacing: 0.1rem;
   font-size: 1.4rem;
   font-weight: 600;
   color: #F76B8A;
}

.ContactContainer a {
   padding: 1rem;
   display: inline-block;
}

.Email {
   text-decoration: none;
   color: #424242;
   font-weight: 500;
   cursor: pointer;
   padding: 1rem;
}

.Facebook {
   text-decoration: none;
   color: #3C5A99;
   font-weight: 500;
   cursor: pointer;
   padding: 1rem;
}

.Facebook span {
   color: #3C5A99;
}