.Bottom {
   background-color: #222831;
   width: 100%;
   margin: 0;
   text-align: center;
   position: static;
   bottom: 0;
   left: 0;
   z-index: 101;
   padding: 2rem;
}

.Bottom a {
    text-decoration: none;
    color: white;
    padding: 0 8rem;
    font-size: 0.85rem;
    cursor: pointer;
}
