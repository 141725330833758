.TimerDate {
   font-family: 'Montserrat Alternates';
   font-weight: 600;
   letter-spacing: 0.1px;
   color: #f06595;
   font-size: 0.9rem;
   padding-top: 1rem;
}

.TimerTime {
   color: #424242;
   font-size: 0.9rem;
   padding-left: 0.5rem;
}
