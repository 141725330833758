.CountdownDisplay {
   display: inline-block;
   min-width: 60%;
   margin: auto;
   margin-bottom: 1rem;
   text-align: center;
   font-family: 'Montserrat';
   font-size: 1rem;
   padding: 0.5rem 1.5rem;
   margin-top: 0.5rem;
   background-color: #f06595;
   background-color: #2B313E;
   color: white;
   border-radius: 30px;
   font-weight: 500;
   letter-spacing: 0.2px;
   box-shadow: 1px 1px 3px rgb(158, 155, 155);
   cursor: pointer;
}


.Display {
   font-size: 0.8rem;
   color: #52616B;
   /* color: #9C999B; */
   font-weight: 600;
}


.CountdownDisplay i {
   color: #ccc;
}
