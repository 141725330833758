
.TimerSample {
    border-radius: 10px;
    margin: auto;
    width: 60%;
    text-align: center;
    box-shadow: 1px 3px 5px #9C999B;
}

.SampleImage {
    width: 100%;
    border-radius: 8px 8px 0 0;
    min-height: 240px;
}

.SampleTitle {
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0 0.2rem;
    text-align: center;
}

.SampleDate {
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
}

.SampleCountdown {
    background-color: #A54064;
    color: white;
    font-weight: 600;
    padding: 0.7rem;
    text-align: center;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
}


@media(min-width: 700px){
    .TimerSample {
        width: 200px;
        box-shadow: none;   
        margin: 0;     
        display: inline-block;
        width: 60%;
        box-shadow: 1px 3px 5px #9C999B;
    }

    .SampleImage {
        height: 180px;
    }

    .SampleTitle {
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }

    .SampleDate {
        font-weight: 700;
    }

    .SampleCountdownDisplay {
        font-weight: 600;
    }
}


@media(min-width: 1000px){
    .TimerSample {
        width: 300px;
        height: 360px;       
        /* position: absolute;
        left: 5rem;
        bottom: 13rem; */
        margin: 0 1rem;
        padding: 0;
        display: inline-block;
        cursor: pointer;
    }
    .SampleImage {
        height: 250px;
    }

    .SampleTitle {
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }

    .SampleDate {
        font-weight: 700;
    }

    .SampleCountdownDisplay {
        font-weight: 600;
    }
}

