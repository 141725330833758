.CardContainer {
   background-color: white;
   font-family: 'Montserrat';
   color: #424242;
   text-align: center;
   width: 95%;
   margin: auto;
   text-align: center;
   box-shadow: 3px 3px 10px #ccc;
   margin-bottom: 1rem;
   border-radius: 10px;
   height: 530px;
   perspective: 1000px;
   transition: transform 1s;
   transform-style: preserve-3d;
}

.Header{
   list-style: none;
   padding: 1rem;
   font-size: 0.9rem;
   padding-top: 2rem;
   color: grey;
}

.Info {
   padding: 0;
   list-style: none;
   /* display: inline-flex;
   justify-content: space-between; */
   padding-bottom: 0;
}

.Info li {
   padding: 0.5rem 1rem;
   font-size: 0.8rem;
   font-weight: 500;
   letter-spacing: 0.2px;
}

.Date {
   font-weight: 500;
   color: #f06595;
   font-size: 0.9rem;
   padding-top: 1rem;
}

.Time {
   color: #424242;
   font-size: 0.9rem;
   padding-left: 0.5rem;
}


.DetailsButton {
   font-size: 0.8rem;
   padding: 0.8rem 1rem;
   padding-top: 0.5rem;
   width: 70%;
   margin: auto;
   border-radius: 20px;
   color: grey;
}

/* .CardContainer:hover {
   position: relative;
   transform: rotateY( 180deg ) ;
   transition: transform 0.5s;
} */

/* .Front, .Back {
   width: 100%;
   height: 100%;
   position: absolute;
   background-color: white;
   backface-visibility: hidden;
   border-radius: 10px;
}

.Back {
   background: white;
   color: #424242;
   transform: rotateY( 180deg );
} */

/* .BottomContainer {
   display: inline-flex;
   justify-content: space-between;
   width: 100%;
   background-color: #2B313E;
   background-color: rgb(161, 161, 161);
   list-style: none;
   color: #424242;
   padding: 1rem 3rem;
   margin-top: 0.5rem;
   box-shadow: 1px 2px 8px grey;
} */

/* .BottomContainer li {
   font-family: 'Montserrat';
   font-size: 0.8rem;
   font-weight: 500;
}

.BottomContainer i {
   font-size: 0.8rem;
} */