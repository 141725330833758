.Navigation {
   background-color: white;
   margin: 0;
   width: 100%;
   height: 60px;
   position: fixed;
   left: 0;
   top: 0;
   z-index: 100;
   align-items: center; 
   display: inline-flex;
   overflow: hidden;
   justify-content: space-between;
}

.Logo {
    font-size: 1rem;
}

.Logo i {
    padding: 1rem;
    padding-right: 0.4rem;
    color: #A54064;
}

.CurrentDateDisplay {
    padding: 1.4rem 1rem;
    font-weight: 400;
    font-size: 0.9rem;
    text-align: right;
}


/* .Notification {
   display: none;
}

.Discovery {
   display: none;
}

.MyTimers {
   display: none;
}

.User {
   display: none;
} */


@media (min-width: 700px) {
   .CurrentDateDisplay {
       font-size: 0.9rem;
       font-weight: 500;
       padding-inline-end: 2rem;
   }

}


@media (min-width: 1000px) {
   .CurrentDateDisplay {
       /* font-size: 1.2rem; */
       /* padding-inline-end: 2rem; */
       /* padding-inline-end: 0.5rem; */
       /* color: #A54064; */
       color: #424242;
       align-items: center;
   }

   .Logo {
       font-size: 1.3rem;
       letter-spacing: 0.5px;
       font-weight: 500;
       color: #424242;
   }

   .Logo img {
       font-size: 1.3rem;
   }
}

/* 
@media (min-width: 1000px) {
   .Navigation li {
       justify-content: space-between;
       color: #484646;
       font-size: 0.9rem;
       font-weight: 500;
       cursor: pointer;
       text-align: center;
       display: inline-block;
       padding: 1.4rem;
   }


   .Notification:hover{
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .Notification.active {
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .Discovery:hover{
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .Discovery.active {
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .MyTimers:hover{
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .MyTimers.active {
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .User {
       color: #428ACD;
   }

   .Logo {
       font-size: 1.3rem;
   }
} */