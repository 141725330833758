html {
  scroll-behavior: smooth;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-height: 700px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  color: #484646;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


  /* font-family: 'Lato', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Muli', sans-serif;
  font-family: 'Roboto', sans-serif; */
.App__App__-hash--base64-5- {
    width: 100%;
    position: relative;
    /* height: 100%; */
    background-color: #eee;
    /* background-color: white; */
    /* background-color: #A5DAF1; */
    overflow: hidden;
}

.navigation__Navigation__-hash--base64-5- {
   background-color: white;
   margin: 0;
   width: 100%;
   height: 60px;
   position: fixed;
   left: 0;
   top: 0;
   z-index: 100;
   align-items: center; 
   display: inline-flex;
   overflow: hidden;
   justify-content: space-between;
}

.navigation__Logo__-hash--base64-5- {
    font-size: 1rem;
}

.navigation__Logo__-hash--base64-5- i {
    padding: 1rem;
    padding-right: 0.4rem;
    color: #A54064;
}

.navigation__CurrentDateDisplay__-hash--base64-5- {
    padding: 1.4rem 1rem;
    font-weight: 400;
    font-size: 0.9rem;
    text-align: right;
}


/* .Notification {
   display: none;
}

.Discovery {
   display: none;
}

.MyTimers {
   display: none;
}

.User {
   display: none;
} */


@media (min-width: 700px) {
   .navigation__CurrentDateDisplay__-hash--base64-5- {
       font-size: 0.9rem;
       font-weight: 500;
       -webkit-padding-end: 2rem;
               padding-inline-end: 2rem;
   }

}


@media (min-width: 1000px) {
   .navigation__CurrentDateDisplay__-hash--base64-5- {
       /* font-size: 1.2rem; */
       /* padding-inline-end: 2rem; */
       /* padding-inline-end: 0.5rem; */
       /* color: #A54064; */
       color: #424242;
       align-items: center;
   }

   .navigation__Logo__-hash--base64-5- {
       font-size: 1.3rem;
       letter-spacing: 0.5px;
       font-weight: 500;
       color: #424242;
   }

   .navigation__Logo__-hash--base64-5- img {
       font-size: 1.3rem;
   }
}

/* 
@media (min-width: 1000px) {
   .Navigation li {
       justify-content: space-between;
       color: #484646;
       font-size: 0.9rem;
       font-weight: 500;
       cursor: pointer;
       text-align: center;
       display: inline-block;
       padding: 1.4rem;
   }


   .Notification:hover{
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .Notification.active {
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .Discovery:hover{
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .Discovery.active {
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .MyTimers:hover{
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .MyTimers.active {
       background-color: #A54064;
       color: white;
       font-weight: 600;
   }

   .User {
       color: #428ACD;
   }

   .Logo {
       font-size: 1.3rem;
   }
} */
.bottomNavigation__Bottom__-hash--base64-5- {
   background-color: #222831;
   width: 100%;
   margin: 0;
   text-align: center;
   position: static;
   bottom: 0;
   left: 0;
   z-index: 101;
   padding: 2rem;
}

.bottomNavigation__Bottom__-hash--base64-5- a {
    text-decoration: none;
    color: white;
    padding: 0 8rem;
    font-size: 0.85rem;
    cursor: pointer;
}

.bottomNavigationMobile__BottomNavigationMobile__-hash--base64-5- {
   width: 100%;
   text-align: center;
   background-color: #33B3C3;
   color: white;
   position: fixed;
   bottom: 0;
   left: 0;
   z-index: 101;
   height: 3.6rem; 
   /* background-color: #A54064;
   background-color: #20c997;
   background-color: #4dabf7;
   background-color: #03060A;
   background-color: #237A71;
   background-color: #33B3C3;
   background-color: #48515E; */
}

.bottomNavigationMobile__Container__-hash--base64-5- {
   width: 60%;
   height: 100%;
   align-items: center;
   display: inline-flex;
   justify-content: space-between;
}

.bottomNavigationMobile__Container__-hash--base64-5- span {
   font-size: 1.2rem;
}



.categoryNavigation__Container__-hash--base64-5- {
   font-size: 0.9rem;
   margin: auto;
   margin-top: 5rem;
   padding: 0 2rem;
   width: 100%;
   text-align: center;
   margin-bottom: 5rem;
   background-color: #eee;
}

.categoryNavigation__Container__-hash--base64-5- div {
   background-color: #A54064;
   color: white;
   font-weight: 500;
   border-radius: 10px;
   margin: 0.4rem;
   padding: 0.5rem 0.7rem;
   font-size: 0.8rem;
   float: left;
   text-align: center;
   cursor: pointer;
   /* box-shadow: 1px 1px 2px #151414; */
   text-transform: capitalize;
   overflow: hidden;
}


.categoryNavigation__fas__-hash--base64-5- {
   font-size: 0.8rem;
}

.categoryNavigation__Container__-hash--base64-5- div:hover {
   background-color: #F76B8A;
   color: white;
   cursor: pointer;
}

.categoryNavigation__Container__-hash--base64-5- div.categoryNavigation__active__-hash--base64-5- {
   background-color: #F76B8A;
   color: white; 
}

@media (min-width: 700px) {
   .categoryNavigation__Container__-hash--base64-5- {
       font-size: 1rem;
       margin: 1rem;
       margin-top: 5rem;
   }
  
   .categoryNavigation__Container__-hash--base64-5- div {
       font-weight: 600;
       border-radius: 5px;
   }
}

@media (min-width: 1000px) {
   .categoryNavigation__Container__-hash--base64-5- {
      margin: 9rem 2rem;
      margin-top: 5rem;
   }

   .categoryNavigation__Container__-hash--base64-5- div {
       font-weight: 600;
       border-radius: 5px;
       margin: 0.7rem;
       padding: 0.6rem 0.7rem;
       font-size: 1.1rem;
   }
}
.scrollUp__Container__-hash--base64-5- {
    background-color: white;
    /* box-shadow: 1px 2px 2px #ccc; */
    width: 3rem;
    height: 3rem;
    position: fixed;
    bottom: 5.5rem;
    right: 0.6rem;
    z-index: 99;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    border: none;
    outline: none;
    cursor: pointer;
}

.scrollUp__Container__-hash--base64-5-:hover {
    background-color: #F76B8A;
}


.scrollUp__Container__-hash--base64-5- i {
    vertical-align: middle;
    color: #ccc;
    color: #F76B8A;
    font-size: 1.2rem;
    padding: 0.9rem;
}

.scrollUp__Container__-hash--base64-5- i:hover {
    color: white;
}

@media (min-width: 1000px) {
    .scrollUp__Container__-hash--base64-5- {
        right: 1.5rem;
        bottom: 6rem;
    }
}
.timerCard__CardContainer__-hash--base64-5- {
   background-color: white;
   font-family: 'Montserrat';
   color: #424242;
   text-align: center;
   width: 95%;
   margin: auto;
   text-align: center;
   box-shadow: 3px 3px 10px #ccc;
   margin-bottom: 1rem;
   border-radius: 10px;
   height: 530px;
   -webkit-perspective: 1000px;
           perspective: 1000px;
   transition: -webkit-transform 1s;
   transition: transform 1s;
   transition: transform 1s, -webkit-transform 1s;
   -webkit-transform-style: preserve-3d;
           transform-style: preserve-3d;
}

.timerCard__Header__-hash--base64-5-{
   list-style: none;
   padding: 1rem;
   font-size: 0.9rem;
   padding-top: 2rem;
   color: grey;
}

.timerCard__Info__-hash--base64-5- {
   padding: 0;
   list-style: none;
   /* display: inline-flex;
   justify-content: space-between; */
   padding-bottom: 0;
}

.timerCard__Info__-hash--base64-5- li {
   padding: 0.5rem 1rem;
   font-size: 0.8rem;
   font-weight: 500;
   letter-spacing: 0.2px;
}

.timerCard__Date__-hash--base64-5- {
   font-weight: 500;
   color: #f06595;
   font-size: 0.9rem;
   padding-top: 1rem;
}

.timerCard__Time__-hash--base64-5- {
   color: #424242;
   font-size: 0.9rem;
   padding-left: 0.5rem;
}


.timerCard__DetailsButton__-hash--base64-5- {
   font-size: 0.8rem;
   padding: 0.8rem 1rem;
   padding-top: 0.5rem;
   width: 70%;
   margin: auto;
   border-radius: 20px;
   color: grey;
}

/* .CardContainer:hover {
   position: relative;
   transform: rotateY( 180deg ) ;
   transition: transform 0.5s;
} */

/* .Front, .Back {
   width: 100%;
   height: 100%;
   position: absolute;
   background-color: white;
   backface-visibility: hidden;
   border-radius: 10px;
}

.Back {
   background: white;
   color: #424242;
   transform: rotateY( 180deg );
} */

/* .BottomContainer {
   display: inline-flex;
   justify-content: space-between;
   width: 100%;
   background-color: #2B313E;
   background-color: rgb(161, 161, 161);
   list-style: none;
   color: #424242;
   padding: 1rem 3rem;
   margin-top: 0.5rem;
   box-shadow: 1px 2px 8px grey;
} */

/* .BottomContainer li {
   font-family: 'Montserrat';
   font-size: 0.8rem;
   font-weight: 500;
}

.BottomContainer i {
   font-size: 0.8rem;
} */
.timerImage__TimerImage__-hash--base64-5- {
   margin: 0;
   padding: 0;
}

.timerImage__TimerImage__-hash--base64-5- img {
   margin: 0;
   padding: 0;
   margin-top: 0.5rem;
   width: 11rem;
   height: 11rem;
   border-radius: 50%;
   cursor: pointer;
}

.categoryIcon__Category__-hash--base64-5- {
   font-size: 0.7rem;
   font-weight: 400;
   color: #2B313E;
   color: gray;
}

.categoryIcon__Category__-hash--base64-5- i {
   padding-right: 0.15rem;
   font-size: 0.7rem;
}

.categoryIcon__DefaultCategory__-hash--base64-5- {
   display: none;
}

.categoryIcon__Category__-hash--base64-5-:nth-child(2) {
   padding-left: 1rem;
}
.timerTitle__Title__-hash--base64-5- {
   display: inline-block;
   font-size: 1.2rem;
   font-weight: 500;
   letter-spacing: 0.1rem;
   padding: 0;
   height: 48px;
   width: 90%;
   overflow: hidden;
}

.timerDescription__Description__-hash--base64-5- {
   font-size: 0.7rem;
   padding: 0.5rem 1rem;
   /* padding-bottom: 0; */
   letter-spacing: 0.2px;
   color: grey;
   margin: 0;
   height: 2rem;
}

.timerDate__TimerDate__-hash--base64-5- {
   font-family: 'Montserrat Alternates';
   font-weight: 600;
   letter-spacing: 0.1px;
   color: #f06595;
   font-size: 0.9rem;
   padding-top: 1rem;
}

.timerDate__TimerTime__-hash--base64-5- {
   color: #424242;
   font-size: 0.9rem;
   padding-left: 0.5rem;
}

.countdownDisplay__CountdownDisplay__-hash--base64-5- {
   display: inline-block;
   min-width: 60%;
   margin: auto;
   margin-bottom: 1rem;
   text-align: center;
   font-family: 'Montserrat';
   font-size: 1rem;
   padding: 0.5rem 1.5rem;
   margin-top: 0.5rem;
   background-color: #f06595;
   background-color: #2B313E;
   color: white;
   border-radius: 30px;
   font-weight: 500;
   letter-spacing: 0.2px;
   box-shadow: 1px 1px 3px rgb(158, 155, 155);
   cursor: pointer;
}


.countdownDisplay__Display__-hash--base64-5- {
   font-size: 0.8rem;
   color: #52616B;
   /* color: #9C999B; */
   font-weight: 600;
}


.countdownDisplay__CountdownDisplay__-hash--base64-5- i {
   color: #ccc;
}

.followTimer__Folow__-hash--base64-5- {
    position: relative;
}

.followTimer__Follow__-hash--base64-5- i {
    position: absolute;
    top: 1.9rem;
    right: 1.2rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.followTimer__Available__-hash--base64-5- {
    position: absolute;
    background-color: hotpink;
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
    border-radius: 5px;
    top: 3rem;
    right: 1rem;
    display: none;
}

.followTimer__Follow__-hash--base64-5-:hover p{
    display: block;
}

.timerContainer__TimerContainer__-hash--base64-5- {
   width: 85%;
   min-height: 700px;
   margin: auto;
   padding-top: 1rem;
   padding-bottom: 5rem;
   /* display: flex; */
   display: grid;
   grid-template-columns: 100%;
   grid-auto-rows: minmax(100px, auto);
   grid-gap: 1.5rem;
   /* margin-left: -8px;
   min-height: 70.5vh;
   margin: auto;
   text-align: center;
   position: relative;
   top: 1.5rem;
   display: table; */
   overflow: hidden;
}


@media (min-width: 700px) {
   .timerContainer__TimerContainer__-hash--base64-5- {
        grid-template-columns: repeat(2, 47%);
        /* min-height: 1000px; */
        width: 90%;
        grid-gap: 2.5rem;
   }
}

@media (min-width: 1000px) {
   .timerContainer__TimerContainer__-hash--base64-5- {
       margin: auto;
       width: 90%;
       grid-gap: 2rem;
       grid-template-columns: repeat(3, 31%);
       margin-bottom: 2rem;
       /* min-height: 700px; */
   }
}

@media (min-width: 1200px) {
   .timerContainer__TimerContainer__-hash--base64-5- {
       margin: auto;
       width: 90%;
       grid-gap: 1.5rem;
       grid-template-columns: repeat(4, 23.5%);
       margin-bottom: 2rem;
       /* min-height: 700px; */
   }
}

@media (min-width: 1500px) {
   .timerContainer__TimerContainer__-hash--base64-5- {
       grid-template-columns: repeat(5, 18.5%);
       margin-bottom: 3rem;
   }
}
.loadMoreButton__LoadMoreButton__-hash--base64-5- {
    width: 90%;
    border-radius: 6px;
    margin: auto;
    text-align: center;
    background-color: #52616B;
    color: white;
    margin-bottom: 6rem;
    margin-top: 0;
    font-size: 1.5rem;
    padding: 0.6rem;
    box-shadow: 1px 1px 6px grey;
    cursor: pointer;
}


.loadMoreButton__LoadMoreButton__-hash--base64-5-:hover {
    background-color: #F76B8A;
}

@media (min-width: 700px) {
    .loadMoreButton__LoadMoreButton__-hash--base64-5- {
        margin-bottom: 10rem;
        margin-top: 2rem;
    }
}

@media (min-width: 1000px) {
    .loadMoreButton__LoadMoreButton__-hash--base64-5- {
        margin-bottom: 5rem;
    }
}
.homePageContainer__Container__-hash--base64-5- {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: white;
}

.homePageContainer__Header__-hash--base64-5- {
    width: 100%;
    margin: 0;
    padding: 0 4rem;
    color: grey;
    font-weight: 500;
    font-size: 0.9rem;
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
    font-family: 'Montserrat';
    background-color: white;
    list-style: none;
    align-items: center;
    line-height: 3rem;
    border-bottom: 0.25px solid #ddd;
    /* background-color: #f06595; */
    /* background-color: #F76B8A; */
    /* background-color: #20c997; */
}

.homePageContainer__Header__-hash--base64-5- li {
    width: 25%;
    text-align: center;
}

.homePageContainer__Header__-hash--base64-5- li:hover{
    background-color: white;
    color: #f06595;
    width: 25%;
    text-align: center;
}

.about__Conatiner__-hash--base64-5- {
   width: 100%;
   margin: 0;
   padding: 0;
   font-family: 'Montserrat';
}

.about__TitleContainer__-hash--base64-5- {
   font-size: 1.8rem;
   padding: 1rem;
   margin: 0;
   padding-top: 1.5rem;
   background-color: aliceblue;
   color: #424242;
   text-align: center;
}

.about__Main__-hash--base64-5- {
   font-weight: 600;
   margin: 0;
   padding: 0;
   color: #424242;
   font-family: 'Montserrat';
}

.about__MainSubTitle__-hash--base64-5- {
   width: 100%;
   margin: 0;
   padding: 1rem;
   padding-top: 1rem;
   background-color: aliceblue;
   list-style: none;
   font-weight: 500;
   font-style: italic;
   font-size: 0.9em;
   color: #424242;
   display: inline-flex;
   justify-content: space-between;
   margin-bottom: 2rem;
}

.about__MainSubTitle__-hash--base64-5- li {
   padding: 0;
   margin: 0;
   color: #424242;
   padding: 1rem 0.5rem;
   font-family: 'Montserrat';
   font-weight: 400;
   letter-spacing: 0.2px;
}

.about__MainSubTitle__-hash--base64-5- i {
   color: #42CDAD;
   margin-right: 0.3rem;
}

.about__SampleTimers__-hash--base64-5- {
   width: 100%;
}

.about__ValueContainer__-hash--base64-5- {
   /* background-color: red; */
   width: 100%;
   /* display: inline-block; */
   margin: auto;
   padding: 1rem;
   text-align: center;
   font-size: 1rem;
   font-weight: 400;
   font-family: 'Montserrat';
   /* border-bottom: 0.25px solid #eee; */
   padding-bottom: 2rem;
}

.about__ValueTitle__-hash--base64-5- {
   padding-top: 2rem;
   font-size: 1.35rem;
   font-weight: 600;
   color: #F76B8A;
   /* letter-spacing: 1px; */
}

.about__Value__-hash--base64-5-{
   padding: 0 1rem;
   display: inline-flex;
   vertical-align: middle;
   min-height: 200px;
}

.about__DontMissIcon__-hash--base64-5- {
   font-size: 3rem;
   color: #4dabf7;
}

.about__HappyIcon__-hash--base64-5- {
   font-size: 3rem;
   color: #42CDAD;
}

.about__Value1__-hash--base64-5- {
   color: #424242;
   padding: 1rem;
   font-size: 1rem;
   width: 43%;
   margin: 1rem;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
}

.about__Value2__-hash--base64-5- {
   color: #424242;
   padding: 1rem;
   margin: 1rem;
   font-size: 1rem;
   width: 43%;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
}


.about__Method__-hash--base64-5- {
   font-size: 0.93rem;
   font-weight: 500;
   margin: 0;
   padding: 0;
   background-color: #F76B8A;
   color: white;
   text-align: center;
   padding: 1rem;
}

.about__WhatContainer__-hash--base64-5- {
   padding: 1rem;
   text-align: center;
   font-size: 1rem;
   font-family: 'Montserrat';
   width: 100%;
   margin: auto;
   margin-top: 1rem;
   border-radius: 5px;
   /* border-bottom: 0.25px solid #eee; */
   padding-bottom: 1rem;
}

.about__WhatTitle__-hash--base64-5- {
   font-weight: 600;
   color: #F76B8A;
   /* padding-top: 1rem; */
   font-size: 1.25rem;
}

.about__WhatContainer__-hash--base64-5- span {
   color: #42CDAD;
   margin-right: 0.5rem;
}

.about__WhatContainer__-hash--base64-5- img {
   width: 50%;
   height: 50%;
}

.about__ImageTimer__-hash--base64-5- {
   width: 60%;
   margin: auto;
   padding: 0;
}

.about__ImageTimer__-hash--base64-5- img {
   margin: auto;
   align-items: center;
}

.about__TimerTitle__-hash--base64-5- {
   font-weight: 600;
   color: #F76B8A;
   font-size: 1rem;
}

.about__PuffinContainer__-hash--base64-5- {
   text-align: center;
   font-size: 1rem;
   font-family: 'Montserrat';
   width: 90%;
   position: relative;
   margin: auto;
   padding: 1rem;
   padding-bottom: 2rem;
   box-shadow: 1px 2px 5px #ccc;
}

.about__Puffin__-hash--base64-5- {
   width: 10rem;
   height: 10rem;
   border-radius: 50%;
}

.about__PuffinTitle__-hash--base64-5- {
   font-size: 1.3rem;
   font-weight: 700;
   color: #F76B8A;
   letter-spacing: 0.1rem;
}

.about__PuffinText__-hash--base64-5- {
   padding: 0.5rem;
   list-style: none;
}

.about__PuffinText__-hash--base64-5- li {
   font-family: 'Montserrat';
   font-size: 1rem;
   font-weight: 400;
   padding: 0.5rem 0;
   letter-spacing: 0.3px;
}

.about__TextHighlight__-hash--base64-5- {
   font-weight: 500;
}

.about__Credit__-hash--base64-5- {
   font-size: 0.8rem;
   color: #ccc;
}

.about__Bold__-hash--base64-5- {
   font-weight: 700;
}

.about__ContactContainer__-hash--base64-5- {
   padding: 1rem;
   text-align: center;
   font-size: 0.9rem;
   font-family: 'Montserrat';
   padding-bottom: 6rem;
   font-weight: 500;
   padding-top: 2rem;
}

.about__Images__-hash--base64-5- {
   width: 100%;
   padding-top: 1rem;
}

.about__ContactTitle__-hash--base64-5- {
   letter-spacing: 0.1rem;
   font-size: 1.4rem;
   font-weight: 600;
   color: #F76B8A;
}

.about__ContactContainer__-hash--base64-5- a {
   padding: 1rem;
   display: inline-block;
}

.about__Email__-hash--base64-5- {
   text-decoration: none;
   color: #424242;
   font-weight: 500;
   cursor: pointer;
   padding: 1rem;
}

.about__Facebook__-hash--base64-5- {
   text-decoration: none;
   color: #3C5A99;
   font-weight: 500;
   cursor: pointer;
   padding: 1rem;
}

.about__Facebook__-hash--base64-5- span {
   color: #3C5A99;
}

.employee__Container__-hash--base64-5- {
   width: 90%;
   margin: auto;
   font-family: 'Montserrat';
   padding-top: 1rem;
}

.employee__ListContainer__-hash--base64-5- {
   width: 100%;
   list-style: none;
   padding: 1rem;
   margin: auto;
   text-align: center;
   font-size: 1rem;
   border-radius: 5px;
   background-color: #2B313E;
   color: white;
}

.employee__ListContainer__-hash--base64-5- li {
   font-family: 'Montserrat';
   font-size: 1rem;
   font-weight: 400;
   padding: 0.5rem 0;
}

.employee__Title__-hash--base64-5- {
   text-align: center;
   padding: 0.5rem 1rem;
   font-size: 1.4rem;
   font-weight: 700;
   color: #F76B8A;
   letter-spacing: 0.1rem;
   font-family: 'Montserrat';
   padding-top: 2rem;
}

.employee__Italic__-hash--base64-5- {
   font-style: italic;
   font-size: 0.9rem;
}

@media(min-width: 700px){
   .employee__Text__-hash--base64-5- {
       line-height: 1.2rem;
       font-size: 1rem;
       width: 75%;
       display: inline-block;
   }
   .employee__ImageContainer__-hash--base64-5- {
       width: 20%;
       padding: 1rem;
       display: inline-block;
   }
}

@media(min-width: 1000px){
       .employee__Text__-hash--base64-5- {
       line-height: 1.2rem;
       font-size: 1rem;
       width: 55%;
       display: inline-block;
   }
   .employee__ImageContainer__-hash--base64-5- {
       width: 20%;
       padding: 1rem;
       display: inline-block;
   }
}


.sampleCountdown__TimerSample__-hash--base64-5- {
    border-radius: 10px;
    margin: auto;
    width: 60%;
    text-align: center;
    box-shadow: 1px 3px 5px #9C999B;
}

.sampleCountdown__SampleImage__-hash--base64-5- {
    width: 100%;
    border-radius: 8px 8px 0 0;
    min-height: 240px;
}

.sampleCountdown__SampleTitle__-hash--base64-5- {
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0 0.2rem;
    text-align: center;
}

.sampleCountdown__SampleDate__-hash--base64-5- {
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
}

.sampleCountdown__SampleCountdown__-hash--base64-5- {
    background-color: #A54064;
    color: white;
    font-weight: 600;
    padding: 0.7rem;
    text-align: center;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
}


@media(min-width: 700px){
    .sampleCountdown__TimerSample__-hash--base64-5- {
        width: 200px;
        box-shadow: none;   
        margin: 0;     
        display: inline-block;
        width: 60%;
        box-shadow: 1px 3px 5px #9C999B;
    }

    .sampleCountdown__SampleImage__-hash--base64-5- {
        height: 180px;
    }

    .sampleCountdown__SampleTitle__-hash--base64-5- {
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }

    .sampleCountdown__SampleDate__-hash--base64-5- {
        font-weight: 700;
    }

    .sampleCountdown__SampleCountdownDisplay__-hash--base64-5- {
        font-weight: 600;
    }
}


@media(min-width: 1000px){
    .sampleCountdown__TimerSample__-hash--base64-5- {
        width: 300px;
        height: 360px;       
        /* position: absolute;
        left: 5rem;
        bottom: 13rem; */
        margin: 0 1rem;
        padding: 0;
        display: inline-block;
        cursor: pointer;
    }
    .sampleCountdown__SampleImage__-hash--base64-5- {
        height: 250px;
    }

    .sampleCountdown__SampleTitle__-hash--base64-5- {
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }

    .sampleCountdown__SampleDate__-hash--base64-5- {
        font-weight: 700;
    }

    .sampleCountdown__SampleCountdownDisplay__-hash--base64-5- {
        font-weight: 600;
    }
}


.example__Container__-hash--base64-5- {
    width: 100%;
    margin: auto;
    padding: 0;
    overflow: hidden;
    font-family: 'Montserrat';
}

.example__PontimerContainer__-hash--base64-5- {
   text-align: center;
   padding: 1rem;
   font-size: 1rem;
   font-family: 'Montserrat';
   /* padding: 2rem 0; */
   /* border-bottom: 0.25px solid #eee; */
}

.example__PontimerTitle__-hash--base64-5- {
   font-size: 1.3rem;
   font-weight: 600;
   color: #F76B8A;
   margin-bottom: 1.5rem;
}

.example__Types__-hash--base64-5- {
   display: inline-flex;
   min-height: 200px;
   margin-bottom: 1rem;
}

.example__Types__-hash--base64-5- i{
   font-size: 3rem;
   margin: 1rem;
}

.example__Type1__-hash--base64-5- {
   color: #424242;
   padding: 1rem;
   font-size: 1rem;
   width: 44%;
   margin: 0.6rem;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
   font-size: 0.9rem;
}

.example__Type2__-hash--base64-5- {
   color: #424242;
   padding: 1rem;
   margin: 0.6rem;
   font-size: 1rem;
   width: 44%;
   border-radius: 5px;
   box-shadow: 1px 2px 2px #ddd;
   font-size: 0.9rem;
}


.example__ExampleContainer__-hash--base64-5- {
   font-size: 0.8rem;
   padding: 1rem;
   border-radius: 5px;
   background-color: #2B313E;
   color: white;
   padding-bottom: 1rem;
}

.example__ExampleTitle__-hash--base64-5- {
   padding: 1rem;
   color: white;
   font-size: 1.1rem;
   letter-spacing: 0.3px;
   font-weight: 500;
}

.example__SubTitle__-hash--base64-5- {
   color: white;
   font-weight: 500;
   font-style: normal;
   font-size: 0.9rem;
}

.example__SubTitle__-hash--base64-5- i {
   color: #42CDAD;
   padding-top: 1rem;
   font-size: 0.8rem;
}

.example__Example1__-hash--base64-5-, .example__Example2__-hash--base64-5- {
   list-style: none;
   color: white;
   display: inline-flex;
   justify-content: space-between;
   padding: 0.5rem;
   margin: 0;
}

.example__Example1__-hash--base64-5- span {
   color: #F76B8A;
   font-weight: 600;
}

.example__Example2__-hash--base64-5- span {
   color: #F76B8A;
   font-weight: 600;
}

.example__Example1__-hash--base64-5- li {
   font-family: 'Montserrat';
   font-weight: 500;
   background-color: white;
   color: #424242;
   width: 29%;
   height: 170px;
   border-radius: 5px;
    padding: 0.2rem;
}

.example__Example2__-hash--base64-5- li {
   font-family: 'Montserrat';
   font-weight: 500;
   background-color: white;
   color: #424242;
   width: 29%;
   height: 170px;
   border-radius: 5px;
   padding: 0.2rem;
}

.example__ExampleImage__-hash--base64-5- {
   width: 100%;
   height: 55%;
   border-radius: 5px 5px 0 0;
   padding: 0;
   margin: 0;
}

.example__ExampleClosure__-hash--base64-5- {
   padding: 1rem;
}

.example__Highlight__-hash--base64-5- {
   color: #42CDAD;
   font-weight: 600;
}

.packages__Container__-hash--base64-5- {
   width: 100%;
   margin: 0;
   padding: 0;
   overflow: hidden;
}

.packages__HeaderContainer__-hash--base64-5- {
   text-align: center;
   background-color: aliceblue;
   padding-top: 2.5rem;
   padding-bottom: 1.5rem;
}

.packages__Head__-hash--base64-5- {
   padding: 0;
   font-size: 1.5rem;
   font-weight: 600;
   letter-spacing: 0.3px;
}

.packages__SubHeader__-hash--base64-5- {
   padding: 1rem;
   padding-bottom: 0.5rem;
   color: grey;
   font-weight: 500;
}

.packages__Highlight__-hash--base64-5- {
   color: #42CDAD;
}

.packages__CheckContainer__-hash--base64-5- {
   margin: auto;
   width: 100%;
   color: #ccc;
   list-style: none;
   display: inline-flex;
   justify-content: space-between;
   padding-top: 2rem;
}

.packages__CheckContainer__-hash--base64-5- li {
   width: 32%;
   font-size: 0.85rem;
}

.packages__CheckContainer__-hash--base64-5- i {
   color: #42CDAD;
}

.packages__Bold__-hash--base64-5- {
   font-weight: 600;
   color: #ccc;
}

.packages__PackageInfo__-hash--base64-5- {
   text-align: center;
   padding: 1.5rem 1rem;
   padding-top: 0;
   font-size: 1.1rem;
}

.packages__PackageInfo__-hash--base64-5- p {
   font-size: 0.9rem;
}

.packages__AccountContainer__-hash--base64-5- {
   padding: 1rem 0;
   text-align: center;
   font-size: 0.9rem;
}

.packages__TrialImage__-hash--base64-5- {
   width: 100%;
   text-align: center;
   padding: 1rem;
}

.packages__TrialImage__-hash--base64-5- img {
   width: 60%;
}

.packages__MainTitle__-hash--base64-5- {
   color: #f06595;
   text-align: center;
   letter-spacing: 0.1px;
   font-weight: 600;
   font-size: 1.1rem;
   padding: 1rem;
   padding-top: 2rem;
}

.packages__Credit__-hash--base64-5- {
   padding: 1rem;
   padding-bottom: 4.5rem;
   text-align: center;
   color: #ccc;
   font-size: 0.9rem;
}



.packageTable__Table__-hash--base64-5- {
   color: white;
   font-weight: 400;
   font-size: 0.9rem;
   position: relative;
   text-align: center;
   font-family: 'Montserrat';
   line-height: 2rem;
   border-collapse: collapse;
   border: none;
   margin-left: 0.5rem;
   background-color: #6A6A6A;
   margin-bottom: 3rem;
}

.packageTable__Table__-hash--base64-5- tr:nth-child(odd) {
   background-color: #424242;
}


.packageTable__FreeWidget__-hash--base64-5- {
   background-color: #E1DFDF;
   color: black;
   text-transform: uppercase;
   padding: 0.3rem;
   width: 80px;
}

.packageTable__BasicWidget__-hash--base64-5- {
   background-color: #8BAFB3;
   color: black;
   text-transform: uppercase;
   padding: 0.3rem;
   width: 80px;
}

.packageTable__PremiumWidget__-hash--base64-5- {
   background-color: #42CDAD;
   color: black;
   text-transform: uppercase;
   padding: 0.3rem;
   width: 80px;
}

.packageTable__Features__-hash--base64-5- {
   text-align: left;
   padding-left: 0.2rem;
   font-size: 0.7rem;
   border: none;
}

.packageTable__Show__-hash--base64-5- {
   display: none;
}

.packageTable__PriceF__-hash--base64-5- {
   font-size: 0.9rem;
   border: none;
   color: white;
}

.packageTable__PriceB__-hash--base64-5- {
   font-size: 0.9rem;
   border: none;
   color: white;
}

.packageTable__PriceP__-hash--base64-5- {
   font-size: 0.9rem;
   border: none;
   color: white;
}

.packageTable__Icon__-hash--base64-5- {
   font-size: 0.7rem;
}

.packageTable__Empty__-hash--base64-5- {
   /* background-color: #303030; */
   border: none;
   color: #C4C4C4;
   margin: 1rem;
   font-size: 0.5rem;
   text-transform: uppercase;
   text-align: center;
}

/* .Hide {
   display: none;
} */

@media(min-width: 700px){
   .packageTable__Table__-hash--base64-5- {
       width: 90%;
       margin: auto;
       text-align: center;
       font-size: 1rem;
       margin-top: 3rem;
   }

   .packageTable__Show__-hash--base64-5- {
       display: inline-block;
   }

   /* .Hide {
       display: none;
   } */

   .packageTable__Widgets__-hash--base64-5- {
       width: 100%;
   }

   .packageTable__Features__-hash--base64-5- {
       font-size: 1.2rem;
       height: 3rem;
   }

   .packageTable__FreeWidget__-hash--base64-5- {
       padding: 0.3rem;
       line-height: 10rem;
   }

   .packageTable__BasicWidget__-hash--base64-5- {
       padding: 0.3rem;
   }

   .packageTable__PremiumWidget__-hash--base64-5- {
       padding: 0.3rem;
   }

   .packageTable__PriceF__-hash--base64-5- {
       font-size: 1.1rem;
       width: 25%;
       background-color: #E1DFDF;
       color: black;
   }

   .packageTable__PriceB__-hash--base64-5- {
       font-size: 1.1rem;
       width: 25%;
       background-color: #8BAFB3;
       color: black;
   }

   .packageTable__PriceP__-hash--base64-5- {
       font-size: 1.1rem;
       width: 25%;
       background-color: #42CDAD;
       color: black;
   }

   .packageTable__Empty__-hash--base64-5- {
       font-size: 1.5rem;
       text-align: center;
   }
}



@media(min-width: 1000px){
   .packageTable__Table__-hash--base64-5- {
       width: 85%;
       margin: auto;
       text-align: center;
       margin-bottom: 1rem;
       margin-top: 3rem;
   }

   .packageTable__Show__-hash--base64-5- {
       display: inline-block;
   }

   /* .Hide {
       display: none;
   } */

   .packageTable__Features__-hash--base64-5- {
       font-size: 1rem;
   }
  
   .packageTable__FreeWidget__-hash--base64-5- {
       padding: 0.3rem;
       width: 100px;
       line-height: 4rem;
   }

   .packageTable__BasicWidget__-hash--base64-5- {
       padding: 0.3rem;
       width: 100px;
   }

   .packageTable__PremiumWidget__-hash--base64-5- {
       padding: 0.3rem;
       width: 100px;
   }

   .packageTable__PriceF__-hash--base64-5- {
       font-size: 1.1rem;
       width: 25%;
       background-color: #E1DFDF;
       color: black;
   }

   .packageTable__PriceB__-hash--base64-5- {
       font-size: 1.1rem;
       width: 25%;
       background-color: #8BAFB3;
       color: black;
   }

   .packageTable__PriceP__-hash--base64-5- {
       font-size: 1.1rem;
       width: 25%;
       background-color: #42CDAD;
       color: black;
   }

   .packageTable__Empty__-hash--base64-5- {
       font-size: 1.5rem;
       text-align: left;
   }
}




.choose__Container__-hash--base64-5- {
   color: #424242;
   width: 90%;
   margin: auto;
   text-align: center;
   overflow: hidden;
   font-family: 'Montserrat';
}

.choose__MainContainer__-hash--base64-5- {
   margin: auto;
   width: 100%;
}

.choose__ChooseB__-hash--base64-5- {
   background-color: white;
   border-radius: 7px;
   padding: 2rem 1rem;
   font-weight: 500;
   box-shadow: 1px 3px 8px #ccc;
   margin: 1rem;
   margin-top: 1rem;
}

.choose__Title1__-hash--base64-5- {
   font-size: 1.4rem;
   font-weight: 600;
   letter-spacing: 0.5px;
   color: #58ACBB;
}

.choose__Title2__-hash--base64-5- {
   font-size: 1.4rem;
   font-weight: 600;
   letter-spacing: 0.5px;
   color: #42CDAD;
}

.choose__List__-hash--base64-5- {
   list-style: none;
   padding: 0;
   padding-bottom: 1.5rem;
}

.choose__List__-hash--base64-5- li {
   font-family: 'Montserrat';
   font-weight: 500;
   padding: 0.3rem 0;
}

.choose__List__-hash--base64-5- i {
   padding-right: 0.5rem;
   font-size: 0.9rem;
   color: #42CDAD;
}

.choose__ListTimer__-hash--base64-5- {
   color: grey;
   font-weight: 500;
}

.choose__ChooseP__-hash--base64-5- {
   background-color: white;
   border-radius: 7px;
   padding: 1rem;
   font-weight: 500;
   box-shadow: 1px 3px 8px #ccc;
   margin: 1rem;
   margin-top: 3rem;
   padding-bottom: 2rem;
}

.choose__PackageImage__-hash--base64-5- {
   width: 80%;
}

.choose__Price__-hash--base64-5- {
   font-size: 1.1rem;
   font-weight: 600;
   margin: 0;
}

.choose__ListTitle__-hash--base64-5- {
   font-weight: 600;
   letter-spacing: 0.1px;
   font-size: 1rem;
   padding-top: 1rem;
}

.choose__TrialButton1__-hash--base64-5- {
   font-weight: 600;
   font-size: 1rem;
   background-color: #58ACBB;
   color: white;
   letter-spacing: 0.5px;
   border-radius: 5px;
   padding: 0.8rem;
   margin: 0;
}

.choose__TrialButton2__-hash--base64-5- {
   font-weight: 600;
   font-size: 1rem;
   background-color: #42CDAD;
   color: white;
   letter-spacing: 0.5px;
   border-radius: 5px;
   padding: 0.8rem;
   margin: 0;
}

.choose__FreeTrial__-hash--base64-5- {
   font-size: 1rem;
   padding: 1rem;
   padding-bottom: 0;
}

.choose__SpecialOffer__-hash--base64-5- {
   font-size: 1rem;
   padding: 1rem;
   padding-bottom: 0;
}

.choose__Highlight__-hash--base64-5- {
   color: #42CDAD;
   font-weight: 600;
}

.choose__List__-hash--base64-5- {
   font-size: 0.9rem;
   color: grey;
}




.futurePackages__Future__-hash--base64-5- {
   padding: 2rem 1rem;
   padding-bottom: 1rem;
}

.futurePackages__Title__-hash--base64-5- {
   color: #F76B8A;
   text-align: center;
   letter-spacing: 0.1px;
   font-weight: 600;
   font-size: 1.1rem;
}

.futurePackages__Card__-hash--base64-5- {
   border-radius: 5px;
   padding: 1rem;
   margin: 2rem 0;
   position: relative;
   box-shadow: 1px 2px 10px #C4C4C4;
   line-height: 1.4rem;
   letter-spacing: 0.5px;
   text-align: center;
}

.futurePackages__Name__-hash--base64-5- {
   font-size: 1.2rem;
   font-weight: 500;
   font-family: 'Roboto';
   letter-spacing: 2px;
}

.futurePackages__Icon__-hash--base64-5- {
   position: absolute;
   right: 1.5rem;
   top: 1.8rem;
   font-size: 1.3rem;
}

.futurePackages__Button__-hash--base64-5- {   
   text-align: right;
   color: white;
   background-color: #58ACBB;
   background-color: #F76B8A;
   border: none;
   border-radius: 5px;
   font-weight: 500;
   padding: 0.5rem 1rem;
   font-size: 0.9rem;
}

.futurePackages__Italic__-hash--base64-5- {
   font-style: italic;
   font-size: 0.9rem;
}

.futurePackages__Email__-hash--base64-5-{
   padding: 1rem;
   text-align: center;
   width: 100%;
   font-size: 1rem;
   padding-bottom: 0;
}

.futurePackages__Email__-hash--base64-5- a {
   text-decoration: none;
   color: #484646;
   font-weight: 600;
   cursor: pointer;
}


@media(min-width: 700px){
   .futurePackages__Button__-hash--base64-5-{
       left: 28rem;
       font-size: 1.1rem;
   }
   .futurePackages__Card__-hash--base64-5- {
       font-size: 1.1rem;
   }

   .futurePackages__Future__-hash--base64-5- {
       padding: 0 3rem;
   }
}

@media(min-width: 1000px){
   .futurePackages__Title__-hash--base64-5- {
       padding: 0 2rem;
   }

   .futurePackages__Future__-hash--base64-5- {
       padding: 1.5rem;
   }

   .futurePackages__Packages__-hash--base64-5- {
       display: inline-flex;
       width: 100%;
       text-align: center;
       margin: auto;
   }

   .futurePackages__Card__-hash--base64-5- {
       margin: 2rem;
       text-align: left;
   }
  
   .futurePackages__Button__-hash--base64-5- {
       font-size: 1.2rem;
       position: static;
       float: right;
   }

   .futurePackages__Email__-hash--base64-5- {
       text-align: right;
       padding: 0 2rem;
   }
}

.comingSoon__Container__-hash--base64-5- {
    background-color: white;
    font-size: 1rem;
    font-family: 'Montserrat';
    margin: 0;
}

.comingSoon__PageTitle__-hash--base64-5- {
    padding: 2.5rem 1rem;
    margin: 0;
    text-align: center;
    color: #424242;
    font-weight: 500;
    letter-spacing: 0.15rem;
    font-size: 1.4rem;
    background-color: aliceblue;
}

.comingSoon__Title__-hash--base64-5- {
    padding: 1rem;
    margin: 0;
    text-align: center;
    color: #F76B8A;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: aliceblue;
}

.comingSoon__Text__-hash--base64-5- {
    text-align: center;
    padding: 1rem;
    padding-bottom: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    background-color: aliceblue;
}

.comingSoon__Examples__-hash--base64-5- {
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 0.5rem;
}

.comingSoon__Examples__-hash--base64-5- i {
    color: #424242;
    font-size: 0.9rem;
    padding-right: 0.2rem;
    padding-left: 1rem;
}


.comingSoon__Images__-hash--base64-5- {
   width: 100%;
   margin: auto;
   text-align: center;
   padding: 0;
   margin-top: 2rem;
   margin-bottom: 2rem;
}

.comingSoon__Images__-hash--base64-5- img {
    width: 60%;
    height: 60%;
}

.comingSoon__More__-hash--base64-5- {
    color: rgb(110, 107, 107);
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.comingSoon__EmailContainer__-hash--base64-5- {
    text-align: center;
    width: 100%;
    margin-top: 3rem;
    padding-bottom: 7rem;
}

.comingSoon__EmailButton__-hash--base64-5- {
    font-family: 'Montserrat';
    margin: auto;
    border-radius: 10px;
    max-width: 80%;
    background-color: #F76B8A;
    box-shadow: 1px 2px 2px #ddd;
    color: white;
}

.comingSoon__EmailButton__-hash--base64-5- a {
    font-size: 1rem;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.8rem 1rem;
    font-weight: 600;
}

.comingSoon__EmailButton__-hash--base64-5- i {
    font-weight: 600;
}
